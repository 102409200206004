import { Price } from "../../models/catalogueType"
import { Base, TravelerTab } from "../../models/retrievesAnOrder"
import { useTranslation } from "react-i18next"
import ParagraphCardTraveler from "./ParagraphCardTraveler"

const DetailLuggageCardTraveler = ({
  total,
  totalService,
  traveler,
}: {
  total: Base | undefined
  totalService: Price | undefined
  traveler: TravelerTab
}) => {
  const { t } = useTranslation()
  return (
    <div className="mx-1 md:mx-2">
      {total !== undefined && !traveler.service?.isPaid ? (
        <>
          <ParagraphCardTraveler traveler={traveler} />
          <p className="md:hidden font-avianca text-avianca-black-1 text-sm md:text-lg">
            {t("translation:Management.handLuggageResponsive")}
          </p>
          <p className="font-avianca text-avianca-gray-4 text-xs md:text-base md:mt-1">
            {total ? `${t("translation:Management.10kgPer")} ${total?.currencyCode.replace(/"/g, "") === "COP" ? new Intl.NumberFormat("es-CO", { style: "currency", currency: "COP", minimumFractionDigits: 0 }).format(total.value) : new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(total.value / 100)} ${total?.currencyCode}` : ""}
          </p>
        </>
      ) : (
        ""
      )}
      {total === undefined && !traveler.service?.isPaid ? (
        <>
          <ParagraphCardTraveler traveler={traveler} />
          <p className="md:hidden font-avianca text-avianca-black-1 text-sm md:text-lg">
            {t("translation:Management.handLuggageResponsive")}
          </p>
          <p className="font-avianca text-avianca-gray-4 text-xs md:text-base md:mt-1">
            {totalService?.total !== undefined && totalService?.total >= 0 && totalService?.currencyCode ? `${t("translation:Management.10kgPer")} ${JSON.stringify(totalService?.currencyCode)?.replace(/"/g, "") === "COP" ? new Intl.NumberFormat("es-CO", { style: "currency", currency: "COP", minimumFractionDigits: 0 }).format(totalService?.total) : new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalService?.total / 100)} ${JSON.stringify(totalService?.currencyCode).replace(/"/g, "")}` : ""}
          </p>
        </>
      ) : (
        ""
      )}
      {traveler.service?.isPaid ? (
        <>
          <ParagraphCardTraveler traveler={traveler} />
          <p className="md:hidden font-avianca text-avianca-black-1 text-sm md:text-lg">
            {t("translation:Management.handLuggageResponsive")}
          </p>
          <p className="font-avianca text-avianca-gray-4 text-xs md:text-base md:mt-1">
            {t("translation:Management.10kgIncluded")}
          </p>
        </>
      ) : (
        ""
      )}
    </div>
  )
}

export default DetailLuggageCardTraveler
