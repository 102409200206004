import { DeleteServices, PnrRequest } from "../models/general"
import {
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
} from "../utils/HttpAdapterAxios"
import { RetrievesAnOrder } from "../models/retrievesAnOrder"
import { CatalogueType } from "../models/catalogueType"
import { ResponseService } from "../models/responseService"
import { structureRequests } from "./headers"

export const getRetrievesAnOrder = async (data: PnrRequest): Promise<RetrievesAnOrder | null> => {
  const url = `${structureRequests.baseURLDapi}purchase/orders/${data.recLoc}?lastName=${data.lastName}&showOrderEligibilities=true`
  return await makeGetRequest<RetrievesAnOrder | null>(url, structureRequests.headersWithOID)
}

export const getCatalogueType = async (data: PnrRequest, countryCode: string): Promise<CatalogueType | null> => {
  const url = `${structureRequests.baseURLDapi}shopping/services/by-order?orderId=${data.recLoc}&catalogueType=all&lastName=${data.lastName}`
  if (countryCode === 'CO') {
    return await makeGetRequest<CatalogueType | null>(url, structureRequests.headersWithOIDBOG)
  } else if (countryCode === 'MX') {
    return await makeGetRequest<CatalogueType | null>(url, structureRequests.headersWithOIDMEX)
  } else {
    return await makeGetRequest<CatalogueType | null>(url, structureRequests.headersWithOIDSAL)
  }
}

export const postServices = async (data: PnrRequest, body: any): Promise<ResponseService | null> => {
  const url = `${structureRequests.baseURLDapi}purchase/orders/${data.recLoc}/services?lastName=${data.lastName}`
  return await makePostRequest<ResponseService | null>(url, body, structureRequests.headersWithOID)
}

export const deleteServices = async (data: DeleteServices): Promise<ResponseService | null> => {
  const url = `${structureRequests.baseURLDapi}purchase/orders/${data.pnr.recLoc}/services?lastName=${data.pnr.lastName}&serviceIds=${data.services}`
  return await makeDeleteRequest<ResponseService | null>(url, structureRequests.headersWithOID)
}