import { useTranslation } from "react-i18next"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { getParamsUrl } from "../../redux/paramsUrl/paramsUrlSlice"
import { BoundTab } from "../../models/retrievesAnOrder"
import { setRetrievesAnOrderStateStatus } from "../../redux/retrievesAnOrder/retrievesAndOrderSlice"

const ManagementFooter = ({
  isHasSameLuggage,
  setIsHasSameLuggage,
  handleRequestAddServices,
  bounds,
  currency,
  isActiveCheck,
}: {
  isHasSameLuggage: boolean
  setIsHasSameLuggage: Function
  handleRequestAddServices: Function
  bounds: BoundTab[]
  currency: string
  isActiveCheck: boolean
}) => {
  const { t } = useTranslation()
  const { hasParams, paramsUrl } = useAppSelector(getParamsUrl)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [disabledButton, setDisabledButton] = useState(false)
  const [total, setTotal] = useState<number>(0)

  useEffect(() => {
    if (bounds) {
      const totalBounds = bounds.map((bound) => {
        if (bound.travelers) {
          const totalBound = bound.travelers.reduce(
            (accumulatorBound, traveler) => {
              if (traveler.service?.hasAddLuggage && !traveler.service.isPaid) {
                if (
                  traveler.service?.service?.prices?.unitPrices[0].prices[0]
                    .total.value
                ) {
                  return (
                    traveler.service.service.prices.unitPrices[0].prices[0]
                      .total.value! + accumulatorBound
                  )
                }
                if (
                  !traveler.service.service &&
                  !traveler.service.isPaid &&
                  traveler.service?.hasAddLuggage
                ) {
                  const priceService =
                    bound.serviceCABG?.prices?.unitPrices.find((price) => {
                      return price.travelerIds.find((priceTraveler) => {
                        return priceTraveler.toString() === traveler.id
                      })
                    })
                  if (priceService?.prices[0].total) {
                    return priceService.prices[0].total + accumulatorBound
                  }
                }
                return accumulatorBound
              }
              return accumulatorBound
            },
            0,
          )
          return totalBound
        }
        return 0
      })
      const total = totalBounds.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0,
      )
      return total ? setTotal(total) : setTotal(0)
    }
  }, [bounds])

  const handleButton = async () => {
    setDisabledButton(true)
    dispatch(setRetrievesAnOrderStateStatus("loading"))
    await handleRequestAddServices()
    if (hasParams) {
      return navigate(
        `/payment?recLoc=${paramsUrl?.recLoc}&lastName=${paramsUrl?.lastName}&lang=${paramsUrl?.lang}`,
      )
    }
  }

  return (
    <div className="max-w-[860px] w-full mt-6 md:mt-8 flex flex-wrap justify-between md:justify-around lg:justify-end">
      <div
        className={`w-full flex justify-between sm:w-auto sm:flex-col ${!isActiveCheck ? "mr-8" : ""}`}
      >
        <p className="hidden md:block font-avianca text-avianca-black-1 text-sm font-normal">
          {t("translation:Management.total")}
        </p>
        <p className="md:hidden font-avianca text-avianca-black-1 text-base font-normal">
          {t("translation:Management.totalResponsive")}
        </p>
        <p className="font-avianca-bold md:font-avianca text-avianca-black-1 text-base md:text-2xl self-end">
          <span className="font-avianca-bold mr-1">
            {currency === "COP" ? new Intl.NumberFormat("es-CO", { style: "currency", currency: "COP", minimumFractionDigits: 0 }).format(total) : new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(total / 100)}
          </span>
          {currency}
        </p>
      </div>
      {isActiveCheck ? (
        <div className="hidden md:flex items-center mx-8">
          <label
            className="mx-1 flex items-center p-1 font-avianca text-base cursor-pointer"
            htmlFor="check"
          >
            <input
              type="checkbox"
              className="appearance-none w-[20px] h-[20px] mr-2 bg-avianca-gray-1 border border-black rounded-sm mx-1 checked:bg-icon-active checked:bg-cover checked:border-none cursor-pointer"
              id="check"
              checked={isHasSameLuggage}
              onChange={() => setIsHasSameLuggage(!isHasSameLuggage)}
            />
            {t("translation:Management.sameBaggage")}
          </label>
        </div>
      ) : (
        ""
      )}
      <button
        className={`w-full min-w-[280px] md:min-w-[185px] mt-4 md:mt-0 h-[48px] ${total
          ? !disabledButton
            ? "bg-avianca-red-1 hover:bg-avianca-red-3 active:bg-avianca-red-2"
            : "bg-avianca-red-2"
          : "bg-avianca-gray-7"
          } rounded font-avianca font-bold leading-4 text-white text-sm md:text-base sm:w-auto sm:my-0`}
        onClick={handleButton}
        type="button"
        disabled={total ? false : true}
      >
        {t("translation:Management.confirmAndPay")}
      </button>
    </div>
  )
}

export default ManagementFooter
