import { useEffect, useState } from "react"
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { useAppSelector } from "../../../redux/hooks"
import { getRetrievesAnOrderState } from "../../../redux/retrievesAnOrder/retrievesAndOrderSlice"
import { getPurchaseStatus } from "../../../utils/helpers/helperConfirmation"
import { RetrievesAnOrder } from "../../../models/retrievesAnOrder"
import { PassengerDetail } from "../../../models/general"

function Icon({ id = 0, open = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""
        } h-5 w-5 transition-transform text-avianca-black-1`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  )
}

const AccordionComponent = ({
  transactionStatus,
}: {
  transactionStatus: string
}) => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const retrievesAnOrderState = useAppSelector(getRetrievesAnOrderState)
  const [passengers, setPassengers] = useState<PassengerDetail[]>()
  const [totalPay, setTotalPay] = useState(0)
  const [currencyCode, setCurrencyCode] = useState("")
  const [open, setOpen] = useState(0)

  const handleOpen = (value = 0) => setOpen(open === value ? 0 : value)

  useEffect(() => {
    if (state) {
      let data: any = state
      let { authorizationCode } = data
      if (retrievesAnOrderState?.retrievesAnOrder) {
        handlePurchaseStatus(
          retrievesAnOrderState?.retrievesAnOrder,
          authorizationCode,
          transactionStatus,
        )
      }
    }
  }, [retrievesAnOrderState?.retrievesAnOrder, state, transactionStatus])

  const handlePurchaseStatus = (
    retrievesAnOrder: RetrievesAnOrder,
    authorizationCode: string,
    status: string,
  ) => {
    const paymentRecordsForServices = getPurchaseStatus(
      retrievesAnOrder,
      authorizationCode,
      status,
    )
    setPassengers(paymentRecordsForServices)
    if (retrievesAnOrder?.dictionaries) {
      const currency = Object.entries(
        retrievesAnOrder.dictionaries?.currency,
      )[0][0]
      setCurrencyCode(currency)
    }
    const totalPay = paymentRecordsForServices.reduce(
      (accumulator, currentValue) => {
        return currentValue.value + accumulator
      },
      0,
    )
    setTotalPay(totalPay)
  }

  return (
    <>
      <Accordion
        open={open === 1}
        icon={<Icon id={1} open={open} />}
        className="rounded-lg border bg-white maximos-Dsk-strong"
      >
        <AccordionHeader
          onClick={() => handleOpen(1)}
          className="border-b-0 p-4 lg:py-6 lg:px-8"
        >
          <div className="text-lg lg:text-3xl text-avianca-black-1 font-bold font-avianca leading-normal lg:leading-loose">
            {t("translation:Confirmation.infoPrecio.title")}
          </div>
        </AccordionHeader>
        <AccordionBody className="lg:pt-2 text-base font-normal font-avianca px-4 py-0 lg:px-8">
          {passengers?.map((passenger) => {
            return (
              <div
                className="text-avianca-black-1 font-normal font-avianca leading-normal mb-3 lg:mb-[31px]"
                key={passenger.id}
              >
                <div className="font-bold flex justify-between lg:mb-4 items-center lg:items-stretch">
                  <p className="text-xs lg:text-xl w-7/12 lg:w-3/12">
                    {passenger.nameComplete}
                  </p>
                  <span className="w-7/12 punto-repeat hidden lg:block"></span>
                  <p className="w-5/12 lg:w-2/12 text-xs lg:text-lg text-right">
                    {currencyCode === "COP" &&
                      new Intl.NumberFormat("es-CO", { style: "currency", currency: "COP", minimumFractionDigits: 0 }).format(passenger.value) ||
                      new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(passenger.value / 100)
                    } {currencyCode}
                  </p>
                </div>

                <div className="flex justify-between items-center lg:items-stretch">
                  <p className="w-7/12 lg:w-3/12 text-[11px] lg:text-lg">
                    {`${passenger.count}
                  ${passenger.count > 1
                        ? t("translation:Confirmation.infoPrecio.carry-on2")
                        : t("translation:Confirmation.infoPrecio.carry-on1")
                      }`}
                  </p>
                  <span className="w-7/12 punto-repeat hidden lg:block"></span>
                  <p className="w-5/12 lg:w-2/12 text-[11px] lg:text-lg text-right">
                    {currencyCode === "COP" &&
                      new Intl.NumberFormat("es-CO", { style: "currency", currency: "COP", minimumFractionDigits: 0 }).format(passenger.value) ||
                      new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(passenger.value / 100)
                    } {currencyCode}
                  </p>
                </div>
              </div>
            )
          })}
          <div className="text-avianca-black-1 text-lg font-normal font-avianca leading-normal mt-4 lg:mt-[36px]">
            <div className="font-bold flex justify-between mb-4 lg:mb-6 items-center lg:items-stretch">
              <p className="text-2xl lg:w-4/12 hidden lg:block">
                {t("translation:Confirmation.infoPrecio.total")}
              </p>
              <p className="text-sm lg:w-7/12 block lg:hidden">
                {t("translation:Confirmation.infoPrecio.totalMob")}
              </p>
              <p className="text-sm lg:text-[22px] w-5/12 lg:w-2/12 text-right">
                {currencyCode === "COP" &&
                  new Intl.NumberFormat("es-CO", { style: "currency", currency: "COP", minimumFractionDigits: 0 }).format(totalPay) ||
                  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalPay / 100)
                } {currencyCode}
              </p>
            </div>
          </div>
        </AccordionBody>
      </Accordion>
    </>
  )
}

export default AccordionComponent
