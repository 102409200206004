import {
  PayURequest,
  PayUResponse,
  ViancaPayAuthorizationRequest,
  ViancaPayAuthorizationResponse,
  WorldpayPaymentRequest,
  ViancaPayPaymentRequest,
  ViancaPayPaymentResponse,
  PaymentMethodsRequest,
  PaymentMethodsResponse
} from "../models/payment"
import { makePostRequest } from "../utils/HttpAdapterAxios"
import { structureRequests } from "./headers"

export const postTokenPayU = async (body: PayURequest): Promise<PayUResponse | null> => {
  const url = `${structureRequests.baseURLViancaPay}createTokenPayU`
  return await makePostRequest<PayUResponse | null>(url, body, structureRequests.headersViancaPay)
}

export const postViancaPayAuthorization = async (body: ViancaPayAuthorizationRequest): Promise<ViancaPayAuthorizationResponse | null> => {
  const url = `${structureRequests.baseURLViancaPay}autorizacion`
  return await makePostRequest<ViancaPayAuthorizationResponse | null>(url, body, structureRequests.headersViancaPay)
}

export const postWorldpayAuthorization = async (body: WorldpayPaymentRequest): Promise<any | null> => {
  const url = `${structureRequests.baseURLViancaPay}tokenizacionwp`
  return await makePostRequest<any | null>(url, body, structureRequests.headersWorldpay)
}

export const postViancaPayPayment = async (body: ViancaPayPaymentRequest, token: string): Promise<ViancaPayPaymentResponse | null> => {
  const url = `${structureRequests.baseURLViancaPay}Servicing`
  structureRequests.headersViancaPayWithToken.headers.Authorization = token
  return await makePostRequest<ViancaPayPaymentResponse | null>(url, body, structureRequests.headersViancaPayWithToken)
}

export const postViancaPayPaymentUs = async (body: ViancaPayPaymentRequest, token: string): Promise<ViancaPayPaymentResponse | null> => {
  const url = `${structureRequests.baseURLViancaPay}ServicingUs`
  structureRequests.headersViancaPayWithToken.headers.Authorization = token
  return await makePostRequest<ViancaPayPaymentResponse | null>(url, body, structureRequests.headersViancaPayWithToken)
}

export const postPaymentMethods = async (body: PaymentMethodsRequest): Promise<PaymentMethodsResponse[] | null> => {
  const url = `${structureRequests.baseURLViancaPay}mediosPago`
  return await makePostRequest<PaymentMethodsResponse[] | null>(url, body, structureRequests.headersViancaPay)
}