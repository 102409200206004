import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PnrRequest } from "../../models/general"
import { fetchCatalogueType } from "./catalogueTypeSliceAPI"
import { RootState } from "../store"
import { CatalogueType } from "../../models/catalogueType"

import { getRetrievesAnOrder } from "../../services/requestDapi"

export interface CatalogueTypeState {
  catalogueType?: CatalogueType | null
  Error?: Error
  status: "idle" | "loading" | "failed"
}

const initialState: CatalogueTypeState = {
  status: "idle",
}

export const setCatalogueTypeStateAsync = createAsyncThunk(
  "catalogueTypeState/fetchCatalogueType",
  async (pnr: PnrRequest) => {
    const retrievesAnOrder = await getRetrievesAnOrder(pnr)
    const countryCode: string | undefined = retrievesAnOrder!.data?.creationPointOfSale.countryCode
    const response = await fetchCatalogueType(pnr, countryCode!)
    return response
  },
)

export const catalogueTypeSlice = createSlice({
  name: "catalogueTypeState",
  initialState,
  reducers: {
    setCatalogueTypeState: (state, action: PayloadAction<CatalogueType>) => {
      state.catalogueType = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCatalogueTypeStateAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(setCatalogueTypeStateAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.catalogueType = action.payload
      })
      .addCase(setCatalogueTypeStateAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const getCatalogueTypeState = (state: RootState) => state.catalogueTypeState

export const { setCatalogueTypeState } = catalogueTypeSlice.actions

export default catalogueTypeSlice.reducer