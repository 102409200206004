import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"

async function makeHttpRequest<T>(
  config: AxiosRequestConfig,
): Promise<T | null> {
  try {
    const response: AxiosResponse<T> = await axios(config)
    return response.data
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      console.error("Error de Axios", (error as AxiosError).message)
    } else {
      console.error("Error desconodido", error)
    }
    return null
  }
}

export async function makeGetRequest<T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<T | null> {
  const getRequestConfig: AxiosRequestConfig = {
    method: "GET",
    url,
    ...config,
  }
  return makeHttpRequest<T | null>(getRequestConfig)
}

export async function makePostRequest<T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<T | null> {
  const postRequestConfig: AxiosRequestConfig = {
    method: "POST",
    url,
    data,
    ...config,
  }
  return makeHttpRequest<T>(postRequestConfig)
}

export async function makeDeleteRequest<T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<T | null> {
  const getRequestConfig: AxiosRequestConfig = {
    method: "DELETE",
    url,
    ...config,
  }
  return makeHttpRequest<T | null>(getRequestConfig)
}

export async function makeMultipleRequests<T>(
  requestConfigs: AxiosRequestConfig[],
): Promise<Array<AxiosResponse<T | null>>> {
  try {
    const responses = await axios.all(
      requestConfigs.map((config) => axios(config)),
    )
    return responses
  } catch (error) {
    return Array(requestConfigs.length).fill(null)
  }
}
