import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import {
  getRetrievesAnOrderState,
  setRetrievesAnOrderStateStatus,
} from "../redux/retrievesAnOrder/retrievesAndOrderSlice"
import {
  BoundTab,
  ServiceStateTab,
  TravelerTab,
} from "../models/retrievesAnOrder"
import { getCatalogueTypeState } from "../redux/catalogueType/catalogueTypeSlice"
import {
  handleCurrencyToServices,
  handleNewBounds,
} from "../utils/helpers/helperBounds"
import { PnrRequest, Service } from "../models/general"
import { deleteServices, postServices } from "../services/requestDapi"
import { useNavigate } from "react-router"

const useHandleBounds = () => {
  const { paramsUrl } = useAppSelector((state) => state.paramsUrl)
  const retrievesAnOrderState = useAppSelector(getRetrievesAnOrderState)
  const catalogueTypeState = useAppSelector(getCatalogueTypeState)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [bounds, setBounds] = useState<Array<BoundTab>>()
  const [isHasSameLuggage, setIsHasSameLuggage] = useState<boolean>()
  const [currency, setCurrency] = useState<string>("")
  const [isActiveCheck, setIsActiveCheck] = useState<boolean>()
  const [isHasRequestDeleteActive, setIsHasRequestDeleteActive] = useState<boolean>(false)

  useEffect(() => {
    if (
      retrievesAnOrderState?.retrievesAnOrder &&
      catalogueTypeState.catalogueType
    ) {
      const bounds = handleNewBounds({
        retrievesAnOrder: retrievesAnOrderState?.retrievesAnOrder,
        catalogueType: catalogueTypeState?.catalogueType,
      })
      setBounds(bounds)
    }
  }, [retrievesAnOrderState, catalogueTypeState])

  useEffect(() => {
    if (retrievesAnOrderState.retrievesAnOrder?.dictionaries) {
      const currency = Object.entries(retrievesAnOrderState.retrievesAnOrder.dictionaries.currency)[0][0];
      const countryCode = retrievesAnOrderState.retrievesAnOrder!.data?.creationPointOfSale.countryCode;
      if ((countryCode === "CO" && currency === "COP") || currency === "USD") {
        return setCurrency(currency)
      } else {
        console.log({ status: 'error', detail: 'La reserva no es válida!, monedas válidas COP/USD', currency });
        return navigate("/error")
      }
    }
  }, [retrievesAnOrderState.retrievesAnOrder?.dictionaries, navigate])

  useEffect(() => {
    if (bounds !== undefined) {
      if (isHasSameLuggage === undefined && isActiveCheck === undefined) {
        if (bounds.length === 1) {
          setIsActiveCheck(false)
          return setIsHasSameLuggage(false)
        }
        const activeCheck = bounds.every((bound) => {
          return bound.isActive === true
        })
        setIsHasSameLuggage(activeCheck)
        setIsActiveCheck(activeCheck)
      }
    }
  }, [bounds, isHasSameLuggage, isActiveCheck])

  useEffect(() => {
    if (retrievesAnOrderState.retrievesAnOrder) {
      const response = handleCurrencyToServices(retrievesAnOrderState.retrievesAnOrder)
      if (response?.length) return navigate("/error")
    }
  }, [retrievesAnOrderState.retrievesAnOrder, navigate])

  const handleCABG = ({
    keyBound,
    traveler,
    statusRequest,
  }: {
    keyBound: string
    traveler: TravelerTab
    statusRequest: boolean
  }) => {
    if (bounds !== undefined) {
      return bounds.map((bound) => {
        if (
          (bound.airBoundId === keyBound || isHasSameLuggage) &&
          bound.travelers &&
          (bound.fareFamilyCode === "XS" || bound.fareFamilyCode === "BASIC")
        ) {
          const travelers = bound.travelers.map((travelerBound) => {
            if (
              travelerBound.id === traveler.id &&
              !travelerBound.service?.isPaid &&
              (travelerBound.service?.service === undefined || statusRequest)
            ) {
              const service: ServiceStateTab = {
                hasAddLuggage: !traveler.service?.hasAddLuggage,
                isPaid: traveler.service?.isPaid,
              }
              travelerBound.isLoader = !travelerBound.isLoader
              return { ...travelerBound, service }
            }
            travelerBound.isLoader = false
            return travelerBound
          })
          return {
            ...bound,
            travelers,
          }
        }
        return bound
      })
    }
  }

  const handleStateBoundTravelers = ({
    keyBound,
    traveler,
  }: {
    keyBound: string
    traveler: TravelerTab
  }): BoundTab[] | undefined => {
    if (bounds !== undefined) {
      const newBounds = bounds.map((bound) => {
        if (bound.travelers) {
          const newTravelers = bound.travelers.map((travelerBound) => {
            if (traveler.id === travelerBound.id) {
              if (isHasSameLuggage) {
                travelerBound.isLoader = !travelerBound.isLoader
              }
              if (!isHasSameLuggage) {
                if (bound.airBoundId === keyBound) {
                  travelerBound.isLoader = !travelerBound.isLoader
                }
              }
            }
            return travelerBound
          })
          return { ...bound, travelers: newTravelers }
        }
        return bound
      })
      return newBounds
    }
    return undefined
  }

  const handleToggleCABG = async ({
    keyBound,
    traveler,
  }: {
    keyBound: string
    traveler: TravelerTab
  }) => {
    if (bounds !== undefined) {
      let statusRequestDelete = false
      const stateBound = handleStateBoundTravelers({ keyBound, traveler })
      setBounds(stateBound)
      if (!isHasSameLuggage) {
        if (traveler.service?.hasAddLuggage && traveler.service?.service) {
          await handleRequestDeleteServices({ keyBound, traveler })
          statusRequestDelete = true
        }
      }
      if (isHasSameLuggage) {
        const travelers = bounds.map((bound) => {
          return bound.travelers?.find((travelerBound) => {
            return travelerBound.id === traveler.id
          })
        })
        if (
          traveler.service?.hasAddLuggage &&
          travelers.some((traveler) => traveler?.service?.service)
        ) {
          await handleRequestDeleteServices({ keyBound, traveler })
          statusRequestDelete = true
        }
      }
      const newBounds = handleCABG({
        keyBound,
        traveler,
        statusRequest: statusRequestDelete,
      })
      setBounds(newBounds)
    }
  }

  const handleRequestAddServices = async () => {
    const servicesForAdd = handleServicesAdd()
    const pnr: PnrRequest = {
      recLoc: paramsUrl?.recLoc!,
      lastName: paramsUrl?.lastName!,
    }
    if (servicesForAdd?.length) {
      // use countryCode
      const response = await postServices(pnr, { services: servicesForAdd })
      if (response?.errors) {
        dispatch(setRetrievesAnOrderStateStatus("idle"))
        navigate("/error", { state: { paramsUrl } })
        throw response
      }
    }
  }

  const handleRequestDeleteServices = async ({
    keyBound,
    traveler,
  }: {
    keyBound: string
    traveler: TravelerTab
  }) => {
    const servicesForDelete = handleServicesDelete({ keyBound, traveler })
    const pnr: PnrRequest = {
      recLoc: paramsUrl?.recLoc!,
      lastName: paramsUrl?.lastName!,
    }
    setIsHasRequestDeleteActive(true)
    // use countryCode
    const response = await deleteServices({
      pnr,
      services: servicesForDelete,
    })
    setIsHasRequestDeleteActive(false)
    if (response?.errors) {
      navigate("/error", { state: { paramsUrl } })
      throw response
    }
  }

  const handleServicesAdd = (): Service[] => {
    let servicesForTraveler: Service[] = []
    if (bounds !== undefined) {
      const services = bounds?.map((bound) => {
        let serviceForTraveler: Service[] = []
        const travelers = bound.travelers?.filter((traveler) => {
          return traveler.service?.hasAddLuggage && !traveler.service?.service
        })
        if (travelers !== undefined && bound.serviceCABG?.id) {
          serviceForTraveler = handleTravelers(travelers, bound.serviceCABG?.id)
        }
        return serviceForTraveler
      })
      servicesForTraveler = services.flat()
      return servicesForTraveler
    }
    return servicesForTraveler
  }

  const handleServicesDelete = ({
    keyBound,
    traveler,
  }: {
    keyBound: string
    traveler: TravelerTab
  }): Array<string> => {
    if (bounds) {
      const servicesToDelete = bounds.map((bound) => {
        const travelerService = bound.travelers?.find((travelerBound) => {
          return (
            travelerBound.service?.hasAddLuggage &&
            travelerBound.service.service &&
            !travelerBound.service.isPaid &&
            travelerBound.id === traveler.id
          )
        })
        if (travelerService !== undefined) {
          return {
            ...travelerService,
            idBound: bound.airBoundId,
          }
        }
        return travelerService
      })
      const newServicesToDelete = servicesToDelete.filter(
        (services) => services !== undefined,
      )
      if (!isHasSameLuggage) {
        const findServiceToDelete = newServicesToDelete.find((traveler) => {
          return traveler?.idBound === keyBound
        })
        if (findServiceToDelete !== undefined) {
          return [findServiceToDelete.service!.service!.id]
        }
      }
      return newServicesToDelete.map((traveler) => {
        return traveler!.service!.service!.id
      })
    }
    return []
  }

  const handleTravelers = (
    travelers: TravelerTab[],
    boundId: string,
  ): Service[] => {
    const serviceForTraveler: Service[] = travelers.map(
      (traveler: TravelerTab) => {
        const service: Service = {
          travelerId: traveler.id,
          serviceId: boundId,
          quantity: 1,
        }
        return service
      },
    )
    return serviceForTraveler
  }

  return {
    bounds,
    handleToggleCABG,
    isHasSameLuggage,
    setIsHasSameLuggage,
    handleRequestAddServices,
    currency,
    isActiveCheck,
    isHasRequestDeleteActive,
  }
}

export default useHandleBounds
