export const structureRequests = {
  baseURLDapi: `${import.meta.env.VITE_REACT_APP_APIDXAPI_BASE_URL}/`,
  baseURLViancaPay: `${import.meta.env.VITE_REACT_APP_VIANCAPAY_BASE_URL}/`,

  headersWithOID: {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": import.meta.env.VITE_REACT_APP_APIDXAPI_HEADER_SUBSCRIPTION_KEY,
      OID: "BOGAV08AK",
      Authorization: "",
      Canal: "",
      Accept: "application/json",
    },
  },

  headersWithOIDBOG: {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": import.meta.env.VITE_REACT_APP_APIDXAPI_HEADER_SUBSCRIPTION_KEY,
      OID: "BOGAV08LL",
      Authorization: "",
      Canal: "NSBook",
      Accept: "application/json",
    },
  },

  headersWithOIDMEX: {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": import.meta.env.VITE_REACT_APP_APIDXAPI_HEADER_SUBSCRIPTION_KEY,
      OID: "MEXAV08LL",
      Authorization: "",
      Canal: "NSBook",
      Accept: "application/json",
    },
  },

  headersWithOIDSAL: {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": import.meta.env.VITE_REACT_APP_APIDXAPI_HEADER_SUBSCRIPTION_KEY,
      OID: "SALTA08LL",
      Authorization: "",
      Canal: "NSBook",
      Accept: "application/json",
    },
  },

  headersViancaPay: {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": import.meta.env.VITE_REACT_APP_VIANCAPAY_HEADER_SUBSCRIPTION_KEY,
      Accept: "*/*",
    },
  },

  headersWorldpay: {
    headers: {
      "Content-Type": "application/xml",
      "Ocp-Apim-Subscription-Key": import.meta.env.VITE_REACT_APP_WORLDPAY_HEADER_SUBSCRIPTION_KEY,
      Authorization: "Basic " + import.meta.env.VITE_REACT_APP_WORLDPAY_HEADER_AUTHORIZATION,
    },
  },

  headersViancaPayWithToken: {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": import.meta.env.VITE_REACT_APP_VIANCAPAY_HEADER_SUBSCRIPTION_KEY,
      Accept: "*/*",
      Authorization: "",
    },
  },
}