import { Fragment } from "react"
import bottomIcon from "../../../assets/icons/Bottom.svg"
import { Drawer } from "@material-tailwind/react"
import topIcon from "../../../assets/icons/Top.svg"
import ResumenCompraInfo from "./ResumenCompraInfo"
import { useTranslation } from "react-i18next"
import { getGrandTotalAmount } from "../../../utils/helpers/helperPayment"
import { useAppSelector } from "../../../redux/hooks"
import { getRetrievesAnOrderState } from "../../../redux/retrievesAnOrder/retrievesAndOrderSlice"

const ResumenCompraMob = ({
  currency,
  openBottom,
  setOpenBottom,
}: {
  currency: string
  openBottom: boolean
  setOpenBottom: Function
}) => {
  const { t } = useTranslation()
  const retrievesAnOrderState = useAppSelector(getRetrievesAnOrderState)
  const toggleDrawerBottom = () => setOpenBottom(!openBottom)

  return (
    <>
      {!openBottom && (
        <>
          <img
            className="relative w-full items-center h-6 z-50"
            onClick={toggleDrawerBottom}
            src={topIcon}
            alt=""
          />
          <nav className="bottom-9 w-full flex flex-wrap items-center justify-between px-2 py-3 bg-white h-14 drop-shadow-[0_-1px_2px_rgba(0,0,0,0.1)]">
            <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
              <div className="w-full items-center flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                <div className="w-7/12 text-base font-normal font-avianca leading-normal">
                  {t("translation:Payment.banner.title")}
                </div>
                <div className="w-5/12 text-right text-sm font-bold font-avianca leading-none">
                  {currency === "COP" &&
                    new Intl.NumberFormat("es-CO", { style: "currency", currency: "COP", minimumFractionDigits: 0 }).format(retrievesAnOrderState.retrievesAnOrder !== undefined ? getGrandTotalAmount(retrievesAnOrderState.retrievesAnOrder!) : 0,) ||
                    new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(retrievesAnOrderState.retrievesAnOrder !== undefined ? getGrandTotalAmount(retrievesAnOrderState.retrievesAnOrder!) / 100
                      : 0,)
                  } {currency}
                </div>
              </div>
            </div>
          </nav>
        </>
      )}
      <Fragment>
        <Drawer
          placement="bottom"
          open={openBottom}
          onClose={toggleDrawerBottom}
          className={`${openBottom ? "drawer" : ""}`}
        >
          {openBottom && (
            <>
              <img
                className="absolute bottom-[99%] w-full h-6"
                onClick={toggleDrawerBottom}
                src={bottomIcon}
                alt=""
              />
              <div className="w-full items-center flex justify-between pt-4 px-4">
                <div className="w-7/12 text-base font-normal font-avianca leading-normal">
                  {t("translation:Payment.banner.title")}
                </div>
                <div className="w-5/12 text-right text-sm font-bold font-avianca leading-none">
                  {`$${new Intl.NumberFormat("es-CO").format(
                    retrievesAnOrderState.retrievesAnOrder !== undefined
                      ? getGrandTotalAmount(
                        retrievesAnOrderState.retrievesAnOrder!,
                      )
                      : 0,
                  )} ${currency}`}
                </div>
              </div>
            </>
          )}
          <ResumenCompraInfo currency={currency} />
        </Drawer>
      </Fragment>
      <footer className="bg-avianca-gray-1 min-h-[48px] flex justify-center">
        <div className="container p-3 flex justify-center items-center">
          <p className="text-avianca-gray-5 font-avianca font-normal text-xs">
            {`© Avianca S.A ${new Date().getFullYear()}`}
          </p>
        </div>
      </footer>
    </>
  )
}

export default ResumenCompraMob
